import { useLocalStorage } from '@vueuse/core'
export const useCTStore = defineStore({
    id: 'useCTStore',
    state: () => ({
        sidebarSm: useLocalStorage('lynx/CT/sidebarSm', false),
        sidebarMob: false,
    }),
    getters: {
        sidebarSmValue: (state) => state.sidebarSm,
        sidebarMobValue: (state) => state.sidebarMob,
    },
    actions: {
        // uncheckQty() {
        //   console.log("xaction", this.new.qty_ok);
        //   this.new.qty_ok = false;
        //   console.log("action", this.new.qty_ok);
        // },
    },
    hydrate(state, initialState) {
        // if (process.client) {
        //     state.email = useLocalStorage('pinia/auth/email', '').value;
        // }
    },
})
