<template lang="pug">
.w-full.text-sm.space-y-2.px-4.py-2.bg-yellow-50.text-yellow-600(v-if="showError")
    .flex.flex-1.gap-3
        .flex.self-center
            | Push notification kamu tidak aktif. aktifkan untuk menerima update
        .flex.self-center
            UButton.bg-yellow-200.text-yellow-600.px-3.py-2.rounded-md(
                size="xs"
                color="yellow"
                @click="requestPermission"
            ) Aktifkan Notifikasi
</template>
<script setup lang="ts">
useHead({
    title: "Dashboard",
    script: [
        { src: "https://js.pusher.com/beams/1.0/push-notifications-cdn.js" },
    ],
});
import * as PusherPushNotifications from "@pusher/push-notifications-web";
const store = UseAuthStore();
const config = useRuntimeConfig();
const showError = ref(false);

// onMounted(() => {
//     watch(
//         () => store.meValue?.username,
//         (newVal, oldVal) => {
//             if (newVal !== oldVal) {
//                 console.log(store?.meValue.username);
//                 if ("serviceWorker" in navigator) {
//                     try {
//                         window.addEventListener("load", () => {
//                             navigator.serviceWorker
//                                 .register("/sw.js")
//                                 .then((registration) => {
//                                     console.log(
//                                         "Service Worker registered with scope:",
//                                         registration.scope,
//                                     );
//                                 })
//                                 .catch((error) => {
//                                     console.log(
//                                         "Service Worker registration failed:",
//                                         error,
//                                     );
//                                 });
//                         });
//                     } catch (error) {}
//                 }
//                 if (!("Notification" in window)) {
//                     // console.log("fcmnotif");
//                 }
//                 // Let's check whether notification permissions have already been granted
//                 else if (Notification.permission === "granted") {
//                     showError.value = false;
//                     try {
//                         const beamsClient = new PusherPushNotifications.Client({
//                             instanceId: config?.public?.pusher_beam_id,
//                         });

//                         const beamsTokenProvider =
//                             new PusherPushNotifications.TokenProvider({
//                                 url: `${config?.public?.api_url}/api/core/users/pusher-token/${store.meValue.username}`,
//                             });

//                         beamsClient
//                             .start()
//                             .then(() =>
//                                 beamsClient.setUserId(
//                                     store.meValue.username,
//                                     beamsTokenProvider,
//                                 ),
//                             )
//                             // .then((beamsClient) => beamsClient.getDeviceId())
//                             // .then((deviceId) =>
//                             //     console.log(
//                             //         "Successfully registered with Beams. Device ID:",
//                             //         deviceId,
//                             //     ),
//                             // )
//                             // .then(() => beamsClient.addDeviceInterest("hello"))
//                             // .then(() => beamsClient.getDeviceInterests())
//                             // .then((interests) =>
//                             //     console.log("Current interests:", interests),
//                             // )
//                             .catch(console.error);
//                     } catch (error) {}
//                 } else {
//                     showError.value = true;
//                 }
//             }
//         },
//         { deep: true },
//     );
// });
const requestPermission = () => {
    try {
        Notification.requestPermission();
        showError.value = false;
    } catch (e) {
        // eslint-disable-next-line
    }
};
</script>
