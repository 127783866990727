<template lang="pug">
.w-full.flex.h-full
    .flex.h-full.flex-grow.flex-col.text-neutral-300.rsidebar-overlay(
        class="transition duration-1000 ease-in-out transform-gpu slide-in-from-left"
    )
        .flex.flex-1.flex-col
            .w-full.p-3(class="h-[55px] max-h-[55px]")
            
                //- +++++++++++++++++++++
                //- logo
                //- +++++++++++++++++++++
                .flex.self-center.flex-1(
                    class="h-[55px] max-h-[55px]"
                )
                    //- CTLogo
                    //- AdminDefaultLogo(v-if="$route.name.includes('admin')")
                    //- DefaultLogo(v-else)
                    DefaultLogo
            //- +++++++++++++++++++++
            .flex.flex-1.h-full
                slot

            .flex
                ClientOnly
                    UButton.text-neutral-400.text-sm(
                        size="lg"
                        color="black"
                        variant="ghost"
                        label="Close sidebar"
                        icon="i-mdi-chevron-double-left"
                        @click="coreStore.sidebarMob = !coreStore.sidebarMobValue"
                    )

</template>
<script setup lang="ts">
import { useCTStore } from "@stores/store";
import { DefaultLogo } from "#components";

const coreStore = useCTStore();
const router = useRouter();
router.beforeEach((to, from, next) => {
    coreStore.sidebarMob = false;
    next();
});
</script>
